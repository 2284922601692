import React, { memo } from 'react'
import { filesize } from 'filesize'

import { Container, FileInfo, Preview, TotalSize } from './styles'

const FileList = memo(({ files, handleDelete, t, uploadedTotalSize }) => (
  <Container>
    {files.map((uploadedFile, i) => (
      <li key={i}>
        <FileInfo>
          <Preview src={uploadedFile.preview} />
          <div>
            <strong>{uploadedFile.name}</strong>
            <span>
              {filesize(uploadedFile.size)}
              {''}
              <button type='button' onClick={() => handleDelete(i)}>
                {t('customer-support-delete-upload')}
              </button>
            </span>
          </div>
        </FileInfo>
      </li>
    ))}
    <TotalSize className='total-size'>{`${t(
      'customer-support-uploaded-size-total'
    )}: ${filesize(uploadedTotalSize)}`}</TotalSize>
  </Container>
))

export default FileList
