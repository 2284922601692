export default (division) => {
  if (/30/.test(division)) return 'Parts'

  if (/40/.test(division)) return 'Service'

  if (/70/.test(division)) return 'Technical Publication'

  if (/80/.test(division)) return 'Training'
  
  return ''
}