import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import formatCardValue from './format-cards'

const renderCards = (cards) => {

    const { t } = useTranslation()

    return cards.map((card) => (
        <Card key={card.key} className="i-ar-card">
            <CardContent>
                <Typography className={`i-ar-card-title`} gutterBottom>
                    {t(card.key)}
                </Typography>
      
                <div className="i-ar-card-value">
                    <i className={`${card.class} material-icons i-ar-card-ico`}>{card.ico}</i>
                    <span className="i-ar-value">{formatCardValue(card)}</span>
                </div>
            </CardContent>
        </Card>
      ))
}

export default renderCards