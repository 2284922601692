import React from 'react'
import TableBodyMUI from '../../../component/table/table-body'
import TableCell from '../../../component/table/table-cell'
import TableRow from '../../../component/table/table-row'
import getCellStyles from './get-cell-styles'
import getOverdueClass from './get-overdue-class'
import getProductDescription from './get-product-description'
import customerCodeCell from './customer-code-cell'
import { orderBy } from 'lodash'
import numeral from 'numeral'
import moment from 'moment'
import getNumberInvoices from './get-number-invoices'
import { verifyAccess } from '../../../domain/security'
import { rolesFullAccess } from '../../../App'

const TableBody = (props) => {
  return (
    <TableBodyMUI>
      {orderBy(props.rows, props.orderBy, props.order).map((row, index) => {
        const product = getProductDescription(row.division)

        const labelId = `enhanced-table-checkbox-${index}`
        return (
          <TableRow
            hover
            role='checkbox'
            tabIndex={-1}
            key={row.id}
            className={getCellStyles(row, props.isClosed)}>
            {customerCodeCell({
              row,
              labelId,
              user: props.user,
              isClosed: props.isClosed
            })}
            <TableCell
              align='left'
              data-label-thead='Invoice'
              className={getCellStyles(row, props.isClosed)}>
              {row.number}
            </TableCell>
            <TableCell align='left' data-label-thead='Po Number'>
              {row.purchaseOrderNumber}
            </TableCell>
            <TableCell align='left' data-label-thead='Billing Doc'>
              {getNumberInvoices(row)}
            </TableCell>
            <TableCell
              align='left'
              data-label-thead='Product'
              style={props.isClosed ? { display: 'none' } : {}}>
              {product}
            </TableCell>
            <TableCell align='left' data-label-thead='Issued Date'>
              {moment(row.issuedDate).format('YYYY-MM-DD')}
            </TableCell>
            <TableCell
              align='left'
              data-label-thead='Due Date'
              className={getOverdueClass(row, props.isClosed)}>
              {moment(row.dueDate).format('YYYY-MM-DD')}
            </TableCell>
            <TableCell align='left' data-label-thead='Total'>
              {numeral(row.totalAmount).format('0,0.00')}
            </TableCell>

            {verifyAccess(props.user, rolesFullAccess) ? (
              <TableCell align='left' data-label-thead=''>
                <i
                  style={{ cursor: 'pointer' }}
                  onClick={() => props.removeInterestFree(row.id)}
                  className='material-icons'
                  color='#7C7C7C'>
                  delete_outline
                </i>
              </TableCell>
            ) : null}
          </TableRow>
        )
      })}
    </TableBodyMUI>
  )
}

export default TableBody
