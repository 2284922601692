import React from 'react'
import numeral from 'numeral'
import { useTranslation } from 'react-i18next'

import showCount from './show-count'
import Paynow from '../../paynow'

import { ContainerFooterSub } from './styles'

export default (props) => {
  const { t } = useTranslation()
  return /open/i.test(props.status) ? (
    <div className='i-collapsible-footer-sub'>
      <ContainerFooterSub>
        <div className='show-count-total'>{showCount(props)}</div>
        <div className='show-count-total-currency'>
          <div>
            <span>{t('invoices-title-credit')}</span>
            <span className='i-label-value-total'>
              {numeral(props.getTotalCredit.totalCredit).format('0,0.00')}
            </span>
          </div>
          <div>
            <span>{t('footer-row-total')}</span>
            <span className='i-label-value-total'>
              {numeral(props.getTotalAmount.totalAmount).format('0,0.00')}
            </span>
          </div>

          <Paynow {...props} />
        </div>
      </ContainerFooterSub>
    </div>
  ) : (
    <div></div>
  )
}
