const color = {
  white: '#ffffff',
  antiFlashWhite: '#F1F3F4',
  black: '#000000',
  silver: '#B0B0B0',
  paleSilver: '#CCC4C4',
  sonicSilver: '#777777',
  redOrange: '#f73737',
  nearYellowOrange: '#F7AF37',
  doveGray: '#666666',
  lightBlue: '#005BAF',
  cadetblue: '#A3B5BF',
  embColor: '#100690',
  embColorHover: '#0d0665',
  embColorHoverAlph: 'rgba(16, 6, 144, .1)', 
  techColor: '#008989',
  techColorHover: '#007171',
  techColorHoverAlph: 'rgba(0, 152, 152, .1)'
}

export default color