import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { fetchAuth } from '../../container/auth/__redux__/actions'
import { getAuthenticatedUser } from '../../domain/auth'

import Loading from '../../component/loading'

import { AuthenticatedUi } from './style'

export default () => {
  const params = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    sessionStorage.clear()
    getAuthenticatedUser(params.hash)
      .then((response) => {
        sessionStorage.setItem('token', response.data.body.token)
        fetchAuth(response.data.body)(dispatch)
        if (response.data.body.user.profile.indexOf('OLP_CUSTOMER') !== -1)
          history.push('/app/customer-dashboard')
        else history.push('/app/dashboard')
      })
      .catch((err) => {
        window.location.href = process.env.REACT_APP_URL_PORTAL
      })
  }, [])

  return (
    <AuthenticatedUi>
      <Loading />
    </AuthenticatedUi>
  )
}
