import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import Filter from '../fixed-filter'
import Customer from '../customer-dashboard-list'
import { setReferenceDateFilter } from '../overdue-position/__redux__/actions'
import { fetchCards } from '../customer-dashboard-cards-analytical/__redux__/actions'
import { fetchPaymentInfo } from '../../charts/customer-payment-info/__redux__/actions'
import { fetchCustomerCreditLimit } from '../../charts/credit-limit/__redux__/actions'

const applyAnalyticalFilter = (dispatch) => (selects) => {
  dispatch(fetchCards({ selects }))
  dispatch(fetchPaymentInfo({ selects }))
  dispatch(fetchCustomerCreditLimit({ selects }))
}

export default () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [updateValueSelects, setUpdateValueSelects] = useState(false)

  function clearFilterDash() {
    dispatch(setReferenceDateFilter(moment().format('YYYY-MM-DD')))
    dispatch(fetchCards())
    dispatch(fetchPaymentInfo())
    dispatch(fetchCustomerCreditLimit())
    setUpdateValueSelects(true)
  }

  useEffect(() => {
    if (updateValueSelects) setUpdateValueSelects(false)
  }, [updateValueSelects])

  return (
    <Filter
      buttonName='btn-apply'
      filterFooter='drop-filter-footer'
      menuFilter='dashboard-filter'
      clearInput={true}
      searchInput={false}
      interceptSelectedValue={true}
      onChangeFilter={(selects) => applyAnalyticalFilter(dispatch)(selects)}
      onClearClick={() => clearFilterDash()}
      clearTitle={t('clear')}
      applyTitle={t('apply')}
      buttonTitleCustomerFilter={t('filter')}
      customerDashboardClassName={'drop-filter-customer-dashboard'}>
      <Customer updateValues={updateValueSelects} />
    </Filter>
  )
}
