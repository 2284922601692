import React from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676'
  }
  if (props.isDragReject) {
    return '#ff1744'
  }
  if (props.isFocused) {
    return '#2196f3'
  }
  return '#100690'
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  margin: 15px 0;
  max-width: 520px;
`

export default function StyledDropzone({ onDrop, t, className, ...props }) {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({ onDrop, ...props })

  return (
    <div className='container'>
      <Container
        {...getRootProps({ isFocused, isDragAccept, isDragReject, className })}>
        <input {...getInputProps()} />
        <p>{t('customer-support-upload-message')}</p>
      </Container>
    </div>
  )
}
