export const getSelectsOnLocalstorage = () => {
  const inputSearch = localStorage.getItem('@inputSearch')
  const inputSearchQuotation = localStorage.getItem('@inputSearchQuotation')
  const leader = JSON.parse(localStorage.getItem('@leader'))
  const camCsi = JSON.parse(localStorage.getItem('@camCsi'))
  const customerGroup = JSON.parse(localStorage.getItem('@customerGroup'))
  const customer = JSON.parse(localStorage.getItem('@customer'))
  const customerQuotation = JSON.parse(localStorage.getItem('@customerQuotation'))
  const csi = JSON.parse(localStorage.getItem('@csi'))

  return {
    inputSearch,
    inputSearchQuotation,
    leader,
    camCsi,
    customerGroup,
    customer,
    customerQuotation,
    csi
  }
}

export const mountSelectsToRedux = ({
  leader,
  camCsi,
  customerGroup,
  customer,
  customerQuotation
}) => {
  const selectsStorage = []
  if (leader && leader.value !== '')
    selectsStorage.push({ name: 'leader', selected: leader })
  if (camCsi && camCsi.value !== '')
    selectsStorage.push({ name: 'accountManager', selected: camCsi })
  if (customerGroup && customerGroup.value !== '')
    selectsStorage.push({ name: 'customerGroup', selected: customerGroup })
  if (customer && customer.value !== '')
    selectsStorage.push({ name: 'customer', selected: customer }) 
  if (customerQuotation && customerQuotation.value !== '')
    selectsStorage.push({ name: 'customerQuotation', selected: customerQuotation })
    
  return selectsStorage
}
