import ButtonMui from '@material-ui/core/Button'
import styled, { css } from 'styled-components'
import theme from '../theme'

const Button = styled(ButtonMui)`
  color: ${theme.colors.doveGray};
  font-size: 1.4rem;
  padding: 8px 16px 8px;
  transition: 500ms;  
  background-color: transparent;

  &.i-filter-button-search {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    min-width: 30px;
    z-index: 5;
    border-radius: 0;
    right: 5px;
    color:red;
  }

  &.MuiButtonBase-root {
    font-size: 1.4rem;
    
    &.btn-apply{
      width: 85px;
      height: 40px;
      background: #88D48D 0% 0% no-repeat padding-box;
      border: 1px solid #49BE51;
      border-radius: 3px;
      text-align: left;
      font: 'Regular 12px/15px Montserrat';
      letter-spacing: 0;
      color: #FFFFFF;
      opacity: 1;
    }
    
    &.btn-report{
      width: 100px;
      height: 40px;
      background: #88D48D 0% 0% no-repeat padding-box;
      border: 1px solid #49BE51;
      border-radius: 3px;
      text-align: left;
      font: 'Regular 12px/15px Montserrat';
      letter-spacing: 0;
      color: #FFFFFF;
      opacity: 1;
    }

    &.btn-clear{
      width: 80px;
      height: 40px;
      background: #B4B4B4 0% 0% no-repeat padding-box;
      border: 1px solid #AEAEAE;
      border-radius: 3px;
      text-align: left;
      font: 'Regular 12px/15px Montserrat';
      letter-spacing: 0;
      color: #FFFFFF;
      opacity: 1;
    }
    &.btn-hidden{
      position: absolute;
      right: 0;
    }
    

  }

  &.MuiButton-text {
    color: ${theme.colors.doveGray};
  }

  &.MuiButton-containedPrimary {
    ${props => props.color === "primary" &&
    props.variant === "contained" &&
    css`
      color: ${theme.colors.white};
      background-color: ${theme.colors.embColor};
  
      &:hover {
        background-color: ${theme.colors.embColorHover};
      }
    `}

    &.Mui-disabled {
      color: rgba(0, 0, 0, 0.26);
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0.12);
    }
  }

  &.MuiButton-containedSecondary {
    ${props => props.color === "secondary" &&
    props.variant === "contained" &&
    css`
      color: ${theme.colors.white};
      background-color: ${theme.colors.techColor};
  
      &:hover {
        background-color: ${theme.colors.techColorHover};
      }
    `}

    &.Mui-disabled {
      color: rgba(0, 0, 0, 0.26);
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0.12);
    }
  }

  &.MuiButton-textPrimary {
    ${props => props.color === "primary" &&
    !props.variant && css`
      color: ${theme.colors.embColor};
  
      &:hover {
        background-color: ${theme.colors.embColorHoverAlph};
      }
    `}

    &.Mui-disabled {
      color: rgba(0, 0, 0, 0.26);
    }
  }

  &.MuiButton-textSecondary {
    ${props => props.color === "secondary" &&
    !props.variant && css`
      color: ${theme.colors.techColor};
  
      &:hover {
        background-color: ${theme.colors.techColorHoverAlph};
      }
    `}

    &.Mui-disabled {
      color: rgba(0, 0, 0, 0.26);
    }
  }

  &.i-link {
  color: #009898;
  line-height: 1;
  display: inline-block;

    .i-link-label {
      vertical-align: super;
      display: inline-block;
    }

    .i-link-ico {
      font-size: 22px;
    }

    &:disabled {
      color: #00000042;
    }
  }

  &.i-link-text-uppercase {
    text-transform: uppercase;
  }
`;

export default Button